import React from "react";
import { object, any } from "prop-types";

import { ContainerContent } from "./index";

export const ContainerContentFixed = ({ style, children }) => (
  <ContainerContent style={{ maxWidth: "1200px", margin: "auto", ...style }}>
    {children}
  </ContainerContent>
);

ContainerContentFixed.propTypes = {
  style: object,
  children: any,
};
