import React from "react";
import { object, string, any } from "prop-types";
import { Link } from "gatsby";

export const InternalLink = ({ style, url, children }) => (
  <Link style={style} to={url}>
    {children}
  </Link>
);

InternalLink.propTypes = {
  style: object,
  url: string.isRequired,
  children: any.isRequired,
};
