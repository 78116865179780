export const Colors = {
  PrimaryBlue: "#2fa3ee",
  Teal: "#4bcaad",
  Green: "#86c157",
  Orange: "#d99c3f",
  DarkOrange: "#ce6633",
  Purple: "#a35dd1",
  LightBlue: "#56bcfe",
  SlateBlue: "#97c5e3",
  Black: "#000000",
  DarkGray: "#355071",
  Gray: "#666666",
  LightGray: "#aabed7",
  White: "#ffffff",
};
