import React from "react";

import { Colors } from "../constants/index";

// import {LogoFooter} from "../logos";

const Footer = () => {
  return (
    <footer
      className={"cp-footer"}
      style={{ backgroundColor: "white", fontSize: "0.8rem" }}
    >
      {/*<LogoFooter/>*/}
      <div
        style={{
          textAlign: "center",

          padding: "0.5rem 1rem",
          color: Colors.Gray,
        }}
      >
        © {new Date().getFullYear()} ITcians{" "}
      </div>
    </footer>
  );
};

export default Footer;
