import React from "react";
import { object } from "prop-types";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

export const LogoHeader = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img
      fluid={data["logo"].childImageSharp.fluid}
      style={{ maxWidth: "500px", margin: "auto", ...style }}
    />
  );
};

LogoHeader.propTypes = {
  style: object,
};
