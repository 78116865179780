import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import { LogoHeader } from "./logos";

export const Header = ({ siteTitle }) => (
  <header
    className={"cp-header"}
    style={{
      background: `white`,
      // marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        textAlign: "center",

        // maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        paddingBottom: 0,
      }}
    >
      <h1 style={{ margin: "auto", textAlign: "center" }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <LogoHeader />
        </Link>
      </h1>
      {/*<Row>*/}
      {/*<Col xs={0} md={24}>*/}
      {/*<div style={{ float: "right", marginTop: "-64px" }}>*/}
      {/*<ButtonPrimary style={{ margin: "4px" }}>*/}
      {/*<InternalLink url={InternalLinkTypes.Contact}>*/}
      {/*Contact Us*/}
      {/*</InternalLink>*/}
      {/*</ButtonPrimary>*/}
      {/*</div>*/}
      {/*</Col>*/}
      {/*</Row>*/}
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
