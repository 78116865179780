export const InternalLinkTypes = {
  Home: "/",
  Contact: "/contact",
  About: "/about",
  Appointment: "/appointment",
  Services: "/services",
  Blogs: "/blogs",
  HomeDesigns: "/homedesigns",
  HomeDesignSingleStorey: "/homedesigns/?storey=1",
  HomeDesignDoubleStorey: "/homedesigns/?storey=2",
  FloorPlans: "/floorplans",
  Displays: "/displays",
  Inclusions: "/inclusions",
  Gallery: "/gallery",
  PhotosGallery: "/gallery/photos",
  VideosGallery: "/gallery/videos",
  GetStarted: "/get-started",
  Disclaimer: "/policy/disclaimer",
};
