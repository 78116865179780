import React from "react";
import { object, any } from "prop-types";

export const ContainerContent = ({ style, children }) => (
  <div style={{ padding: "2rem 1rem", ...style }}>{children}</div>
);

ContainerContent.propTypes = {
  style: object,
  children: any.isRequired,
};
